define('Magento_Theme/js/theme',[
    'jquery',
    'owl_carousel'
], function ($) {

    if ($(window).width() > 767) {
        startCarousel();
    } else {
        stopCarousel();
    }
    $(window).resize(function () {
        if ($(window).width() > 767) {
            if (!$('.catalog-link-mobile').hasClass('started')) {
                startCarousel();
                $('.catalog-link-mobile').removeClass('stop');
            }
        } else {
            if (!$('.catalog-link-mobile').hasClass('stop')) {
                stopCarousel();
                $('.catalog-link-mobile').removeClass('started');
            }
        }
    });
    $(document).ready(function(){
        var featured = $('.custom-products-carousel .widget-product-grid');
        featured.owlCarousel({
            items: 5,
            slideSpeed: 800,
            nav: true,
            dots: false,
            autoplay: false,
            loop: true,
            smartSpeed:1500,
            responsive : {
                // breakpoint from 0 up
                0 : {
                    items : 2,
                    nav:true,
                    dots:false
                },
                // breakpoint from 480 up
                480 : {
                    items : 2,
                    nav:true,
                    dots:false
                },
                // breakpoint from 768 up
                768 : {
                    items : 4,
                    nav:true,
                    dots:false
                },
                // breakpoint from 768 up
                940 : {
                    items : 5,
                    nav:true,
                    dots:false
                }
            }
        });
    });
    function startCarousel() {
        $('.catalog-link-mobile').owlCarousel({
            dots: false,
            nav: false,
            loop: true,
            itemsDesktop: false,
            itemsDesktopSmall: false,
            itemsMobile: false,
            item: 4,
            responsive: {
                768: {
                    items: 3
                },
                1024: {
                    items: 4
                }
            }
        });
        $('.catalog-link-mobile').addClass('started');
    }

    function stopCarousel() {
        var owl = $('.catalog-link-mobile');
        owl.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
        owl.find('.owl-stage-outer').children().unwrap();
        $('.catalog-link-mobile').addClass('stop');
    }

    //header my account hover
    var timer;

    function debounce() {
        clearTimeout(timer);
        timer = setTimeout(function () {
            $(".customer-menu").hide();
        }, 450);
    }

    $('.header.links').hover(function (e) {
        $('.customer-menu').show();
        clearTimeout(timer);
    }, function () {
        // hover out
        debounce();
    });
    $('.customer-menu').mouseenter(function (e) {
        clearTimeout(timer);

    });
    $('.customer-menu').mouseleave(function (e) {
        debounce();

    });


});
