define('Kemana_NewsletterCoupon/js/ajax-newsletter-subscriber',[
    'jquery',
    'mage/mage',
], function ($) {
    'use strict';

    $.widget('kemana.ajaxNewsletterSubscriber', {

        /**
         *
         * @private
         */
        _create: function () {
            var self = this;

            this.element.find('form').submit(function() {
                if ($(this).validation('isValid')) {
                    $.ajax({
                        url: $(this).attr('action'),
                        cache: true,
                        data: $(this).serialize(),
                        dataType: 'json',
                        type: 'POST',
                        showLoader: true
                    }).done(function (data) {
                        self.element.parent().find('.messages').empty();
                        if (data.error) {
                            self.element.after('<div class="messages"><div class="message message-error error"><div>' + data.message + '</div></div></div>');
                            setTimeout(function () {
                                self.element.parent().find('.messages').remove();
                            }, 6000);
                        } else {
                            self.element.find('input').val('');
                            self.element.after('<div class="messages"><div class="message message-success success"><div>' + data.message + '</div></div></div>');
                            setTimeout(function () {
                                self.element.parent().find('.messages').remove();
                            }, 6000);
                        }
                    });
                }
                return false;
            });

        },

    });
    return $.kemana.ajaxNewsletterSubscriber;
});

