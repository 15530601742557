/*
    Author  : I.CUBE, inc.
    main jQuery widget of Fpmini
*/

define('planetsports',[
    'jquery',
    'jquery/ui',
    'mage/translate',
    'Born_WeltPixelGtm/js/born_gtm',
    'owl_carousel',
    'slick',
    'fancybox'
], function($, ui, _responsive, born_gtm){
    'use strict';

    $.widget('planetsports.planetsports', {

        _create: function() {
            this.initAllPages();
        },

        initAllPages: function() {
            // ----move header links to nav section----
            $('.page-wrapper > .header.links').appendTo(".page-wrapper .sections.nav-sections .section-items");
            $('.page-wrapper .sections.nav-sections .section-items .header.links').css("display","block");
            // ----move header links to nav section----

            // ----move copyright below newsletter----
            if ($(window).width() > 639) {
                $('.page-wrapper .copyright').appendTo(".footer-container .right > .newsletter > div > .copyright");
            }
            // ----move copyright below newsletter----
            // ---- mobile menu click
            $('.top-menu.nav-mobile *').click(function() {
                var interval = setInterval(function () {
                    if ($('.top-menu.nav-mobile .navigation').hasClass("loaded")) {
                        if ( $('.top-menu.nav-mobile .navigation > .dropdown-menu').length ) {
                            $('.top-menu.nav-mobile').css({'min-height':'auto'});
                            $('.sections.nav-sections .additional-mobile').show();
                            $('.sections.nav-sections .additional-login-mobile').hide();
                            $('.sections.nav-sections #multistore-mobile-switcher-language').show();
                            $('.sections.nav-sections .store-information').show();
                            clearInterval(interval);
                        } else {
                            $('.sections.nav-sections .additional-mobile').show();
                            $('.sections.nav-sections #multistore-mobile-switcher-language').show();
                            $('.sections.nav-sections .store-information').show();
                            $('.top-menu.nav-mobile').css({'min-height':'auto'});
                            if ($(".responsive-main-header .header.links > .customer-welcome").length) {
                                $('.sections.nav-sections .additional-login-mobile').show();
                            }
                            clearInterval(interval);
                        }
                    }
                }, 50);
            });
            $(document).on('click', function(e) {
              var container = $("#suggestionsContainer");
              if (!$(e.target).closest(container).length) {
                container.addClass("ng-hide");
              }
            });
            $(document).ready(function () {
              $('.checkout-cart-index .eds-discount-trigger').click(function(e){
                  e.preventDefault();
                  $('.checkout-cart-index #block-eds-discount').toggleClass('active');
                  $('.checkout-cart-index #block-eds-discount .content').slideToggle("active");
              });
              $("button.btn-success-payment").on('click', function () {
                $("#copyToClipboard").html("Copied");
              });
            });
            //Sticky Add to cart_
            if ($(window).width() < 769) {
                $('.catalog-product-view .box-tocart').addClass('pdpsticky');
            }

             // ---- mobile menu click
            // ---- STICKY HEADER ----

            $.noConflict();
               $(document).ready(function ($) {
                    if ($('body').hasClass("customer-address-form") && ($('body').hasClass("store-view-en_th") || $('body').hasClass("store-view-th_th"))) {
                        var InitIntervalCity = setInterval(function () {
                            if ($('.form-address-edit').find('select[name="cityCopy"] option').length > 1) {
                                var cityVal = $('.form-address-edit').find('input[name="city"]').val();
                                $('.form-address-edit').find('select[name="cityCopy"]').val(cityVal);
                                $('.form-address-edit').find('select[name="cityCopy"]').trigger('change');
                                clearInterval(InitIntervalCity);
                            }
                        },100);
                        var InitInterval = setInterval(function () {
                            if ($('.form-address-edit').find('select[name="kecamatan"] option').length > 1) {
                                $('.form-address-edit').find('select[name="kecamatan"] option:last').attr('selected','selected');
                                $('.form-address-edit').find('select[name="kecamatan"]').trigger('change');
                                $('.form-address-edit').find('select[name="kecamatan"] option:last').prop('selected', true);
                                clearInterval(InitInterval);
                            }
                        },100);
                        $(document).on('change blur', 'select[name="cityCopy"]', function () {
                            $('.form-address-edit').find('input[name="city"]').val($(this).val());
                            var InitIntervalChange = setInterval(function () {
                                if ($('.form-address-edit').find('select[name="kecamatan"] option').length > 1) {
                                    $('.form-address-edit').find('select[name="kecamatan"] option:last').attr('selected','selected');
                                    $('.form-address-edit').find('select[name="kecamatan"]').trigger('change');
                                    $('.form-address-edit').find('select[name="kecamatan"] option:last').prop('selected', true);
                                    clearInterval(InitIntervalChange);
                                }
                            },100);
                        });
                    }
                    if ($('body').hasClass("checkout-index-index") && ($('body').hasClass("store-view-en_th") || $('body').hasClass("store-view-th_th"))
                    ) {
                        var InitInterval = setInterval(function () {
                            if ($('#shipping-new-address-form').find('select[name="custom_attributes[district_id]"] option').length > 1) {
                                 $('#shipping-new-address-form').find('select[name="custom_attributes[district_id]"] option:last').attr('selected', 'selected');
                                 var lastOptVal = $('#shipping-new-address-form').find('select[name="custom_attributes[district_id]"] option:last').val();
                                 $('#shipping-new-address-form').find('select[name="custom_attributes[district_id]"]').val(lastOptVal);
                                 $('#shipping-new-address-form').find('select[name="custom_attributes[district_id]"]').trigger('change');
                                 $('#shipping-new-address-form').find('select[name="custom_attributes[district_id]"] option:last').prop('selected', true);
                                 clearInterval(InitInterval);
                            }
                        },100);
                        $(document).on('change blur', '[name="custom_attributes[city_id]"]', function () {
                            var InitIntervalChange = setInterval(function () {
                                if ($('#shipping-new-address-form').find('select[name="custom_attributes[district_id]"] option').length > 1) {
                                     $('#shipping-new-address-form').find('select[name="custom_attributes[district_id]"] option:last').attr('selected', 'selected');
                                     var lastOptVal = $('#shipping-new-address-form').find('select[name="custom_attributes[district_id]"] option:last').val();
                                     $('#shipping-new-address-form').find('select[name="custom_attributes[district_id]"]').val(lastOptVal);
                                     $('#shipping-new-address-form').find('select[name="custom_attributes[district_id]"]').trigger('change');
                                     $('#shipping-new-address-form').find('select[name="custom_attributes[district_id]"] option:last').prop('selected', true);
                                     clearInterval(InitIntervalChange);
                                }
                            },100);
                        });
                    }

                });

            //$('<div class="scroll-div" />').prependTo('.page-wrapper');
            function stickHeight () {
                if ($(window).width() > 767) {
                    var h = $('header.page-header').height();
                } else {
                    var h = $('.header-main').height();
                }
                $('.scroll-div').height(h);
            }
            stickHeight();
            $(window).resize(function(){
                stickHeight();
            });
            if ($(window).width() > 767){
              $(window).scroll(function(){
                  $(window).scrollTop($(window).scrollTop());
                  if ($(window).scrollTop() === 0) {
                      $('.scroll-div').css('margin-top', 0);
                      if ($(window).width() > 767){
                            $('.header-top-wrap').slideDown('500');
                      }
                  } else {
                     $('.scroll-div').css('margin-top', -100).addClass('sticky-header__transition--05s');
                      if ($(window).width() > 767){
                            $('.header-top-wrap').slideUp('500');
                      }
                  }
              });
          }

            $(document).ready(function(){
              var windowWidthPdp = $(window).width();
              if (windowWidthPdp < 768) {
                $('.search-result ul').on('click', 'li', function() {
                  setTimeout(function () {
                      $('.catalog-product-view .modal-popup').animate({scrollTop: $('#stores-map').offset().top - 100},'slow');
                  },50)
                });
              }
                var max = 0;
                //$('.magnifier-preview').css({"width": "550px !important", "height":"550px !important"});
                $('#sizechart-id table td').each(function() {
                    max = Math.max($(this).width(), max);
                }).width(max);
                //Size chart Horizontal View
                $( ".language-dropdown-container" ).click(function() {
                    $( this ).toggleClass( "open" );
                });
                $('.multistore-switcher ul.weltpixel_multistore li.active.switcher-option').clone().prependTo( ".language-dropdown-container" );
                $(".footer-container .footer-space .collapsible .title").click(function() {
                    $(this).parent().toggleClass( "open" );
                });
                // ----mega menu lv 2 max 7 items per row----
                $('.mega-col-level-1 .nav-item.level1').each(function() {
                    var megaColLevel2 = $(this).find(".mega-col-level-2 .nav-item.level2");
                    var separator = $("<div class='menu-side'></div>");
                    $(megaColLevel2).each(function(i) {
                        if( i % 7 == 0 ) {
                            $(this).nextAll().andSelf().slice(0,7).wrapAll(separator);
                        }
                    });
                });
                // ----mega menu lv 2 max 7 items per row ends----

                $( window ).resize(function() {
                    if ($(window).width() < 801) {
                        if ($(".responsive-main-header .header.links > .customer-welcome").length) {
                            $(".additional-login-mobile").show();
                            $(".login-mobile").hide();
                        } else if ($(".responsive-main-header .header.links > .authorization-link").length) {
                            $(".additional-login-mobile").hide();
                            $(".login-mobile").show();
                        }
                        $('.top-menu.nav-mobile').find('.active').removeClass('active');
                    } else {
                        $(".additional-login-mobile").hide();
                        $(".login-mobile").show();
                    }
                });

                var interval = setInterval(function () {
                    if ($(window).width() < 801) {
                        $('.top-menu.nav-mobile').find('.active').removeClass('active');
                        if ($(".responsive-main-header .header.links > .customer-welcome").length) {
                            $(".additional-login-mobile").show();
                            $(".login-mobile").hide();
                            clearInterval(interval);
                        } else if ($(".responsive-main-header .header.links > .authorization-link").length) {
                            $(".additional-login-mobile").hide();
                            $(".login-mobile").show();
                            clearInterval(interval);
                        }
                    } else {
                        $(".additional-login-mobile").hide();
                        $(".login-mobile").show();
                        clearInterval(interval);
                    }
                }, 1000);
            });
            //minicart mouseover event

            //searchsuggestion mobile click events
            $('[data-role="minisearch-label"]').on('click', function(){
                $(this).closest('.minisearch').addClass('search-active');
                $(this).addClass('search-active');
            });

            $(document).on("touchstart", function(e) {
                if(!$("#suggestionsContainer").is(":visible")){
                    $('[data-role="minisearch-label"]').removeClass('search-active');
                    $('.minisearch').removeClass('search-active');
                }
            });

            $('.cel-ac-icon-cancel').on('click', function(){
               $('[data-role="minisearch-label"]').removeClass('search-active');
               $('.minisearch').removeClass('search-active');
            });

            //Faq Page Email url changes starts Here
            $(document).ready(function() {
              setTimeout(function(){
                var url = window.location.href;
                var page = url.substring(url.lastIndexOf('/') + 1);
                if(page == 'faq#payment'){
                  $('#payment .togglet').addClass('toggleta');
                  $('#payment .togglec').css('display','block');
                  console.log("FAQ" + page);
                    if ($(window).width() < 768) {
                      $('html, body').animate({
                      scrollTop: $("#payment").offset().top-100
                      }, 2000);
                    }
                    if ($(window).width() > 767) {
                      $('html, body').animate({
                                scrollTop: $("#payment").offset().top-250
                      }, 2000);
                    }
                }
              }, 1500);
            });
            //Faq Page Email url changes Ends Here
            // ----recent blog----
                if ($(window).width() < 768) {
                    $(".blog-widget-recent").addClass("mobile-carousel");
                    var blog_recent = $('.blog-widget-recent.mobile-carousel .post-list');
                    blog_recent.owlCarousel({
                    items: 4,
                    slideSpeed: 800,
                    nav: true,
                    dots: false,
                    loop: true,
                    responsive : {
                        // breakpoint from 0 up
                        0 : {
                            items : 1,
                            nav:false,
                            dots:false
                        },
                        // breakpoint from 481 up
                        480 : {
                            items : 2,
                            nav:false,
                            dots:false
                        },
                        // breakpoint from 481 up
                        640 : {
                            items : 3,
                            nav:false,
                            dots:false
                        }
                    }
                });

                }
            // ----recent blog----
                    }
    });

    return $.planetsports.main;
});

