define('Born_WeltPixelGtm/js/born_gtm',[
    'jquery',
], function ($) {
    "use strict";
    return {
        /**
         * push social links click data.
         */
        triggerSocialLinksClick: function () {
            $('.footer-social-links a.social').on('click', function () {
                var media = $(this).attr('id');
                media = media[0].toUpperCase() + media.slice(1);
                dataLayer.push({
                    'event': "followSocial",
                    'eventCategory': "Custom Event",
                    'eventAction': "Social Media Platform",
                    'eventLabel': media
                });
            });
        },

        /**
         * push store locator click data.
         */
        triggerStoreLocatorClick: function () {
            $('ul.list-store-container li.store-item').on('click', function () {
                var storeName = $(this).attr('title');
                dataLayer.push({
                    'event': "storeLocator",
                    'eventCategory': "Custom Event",
                    'eventAction': "Select Store Locator",
                    'selectedStoreName': storeName
                });
            });
        },

        /**
         * push promotion impressions data.
         */
        triggerPromotionImpressions: function () {
            var promotionViews = [];
            $('[data-track-promo-id]').each(function () {
                var promoId = $(this).attr('data-track-promo-id'),
                    promoName = $(this).attr('data-track-promo-name'),
                    promoCreative = $(this).attr('data-track-promo-name'),
                    promoPosition = $(this).attr('data-track-promo-position');
                promotionViews.push({
                    'name': promoName,
                    'id': promoId,
                    'creative': promoCreative,
                    'position': promoPosition
                });
            });
            if (promotionViews.length) {
                dataLayer.push({
                    'event': "promotionImpression",
                    'ecommerce': {
                        'promoView': {
                            'promotions': promotionViews
                        }
                    }
                });
            }
        },

        /**
         * push promotion click data.
         */
        triggerPromotionClick: function () {
            $('[data-track-promo-id]').on('click', function () {
                var promoId = $(this).attr('data-track-promo-id'),
                    promoName = $(this).attr('data-track-promo-name'),
                    promoCreative = $(this).attr('data-track-promo-name'),
                    promoPosition = $(this).attr('data-track-promo-position');
                dataLayer.push({
                    'event': "promotionClick",
                    'ecommerce': {
                        'promoClick': {
                            'promotions': [{
                                'name': promoName,
                                'id': promoId,
                                'creative': promoCreative,
                                'position': promoPosition
                            }]
                        }
                    },
                });
            });
        },

        /**
         * push product impression.
         */
        triggerProductImpression: function () {
            var products = [], currencyCode = 'IDR';
            $('[data-track-info]').each(function () {
                if (!$(this).parent().parent().hasClass('cloned')) {
                    var productInfo = $(this).attr('data-track-info');
                    productInfo = $.parseJSON(productInfo);
                    currencyCode = productInfo.currencyCode;
                    delete productInfo.currencyCode;
                    products.push(productInfo);
                }
            });
            if (products.length) {
                dataLayer.push({
                    'event': 'productImpression',
                    'ecommerce': {
                        'currencyCode': currencyCode,
                        'impressions': products
                    }
                });
            }
        },

        /**
         * push product click data.
         */
        triggerProductClick: function () {
            $('[data-click-info]').on('click', function () {
                var productInfo = $(this).attr('data-click-info');
                productInfo = $.parseJSON(productInfo);
                delete productInfo.currencyCode;
                dataLayer.push({
                    'event': "productClick",
                    'ecommerce': {
                        'currencyCode': "IDR",
                        'click': {
                            'actionField': {'list': productInfo.list},
                            'products': [{
                                'name': productInfo.name,
                                'id': productInfo.sku,
                                'price': productInfo.price,
                                'brand': productInfo.brand,
                                'category': productInfo.category,
                                'position': productInfo.position,
                                'dimension22': productInfo.dimension22
                            }]
                        }
                    }
                });
            });
        },

        /**
         * push brand click data.
         */
        triggerBrandClick: function () {
            $('.brands-logo [data-brand]').on('click', function () {
                var brand = $(this).attr('data-brand');
                dataLayer.push({
                    'event': "brandClick",
                    'eventCategory': "Custom Event",
                    'eventAction': "Brand Menu Click",
                    'eventLabel': brand
                });
            });
        },

        /**
         * push order track data.
         *
         * @param orderId
         * @param status
         */
        triggerOrderTrack: function (orderId, status) {
            dataLayer.push({
                'event': "trackingOrder",
                'eventCategory': "Custom Event",
                'eventAction': "Tracking Order Click",
                'eventLabel': status,
                'orderID': orderId
            });
        },

        /**
         * push chart track data.
         */
        triggerChartTrack: function () {
            var productInfo = $.parseJSON(window.productInformation);
            $('.product-options-wrapper .open-chart').on('click', function () {
                dataLayer.push({
                    'event': "sizeChartClick",
                    'eventCategory': "Product Engagement",
                    'eventAction': "Size Chart Click",
                    'eventLabel': productInfo.url,
                    'productName': productInfo.name,
                    'productId': productInfo.id,
                    'productSku': productInfo.sku,
                    'productBrand': productInfo.brand,
                    'productPrice': productInfo.price,
                    'productCategory': productInfo.category
                });
            });
        },

        /**
         * push social share track data.
         */
        triggerSocialShareTrack: function () {
            var productInfo = $.parseJSON(window.productInformation);
            $('.product-share-this .st_facebook_large, .product-share-this .st_twitter_large').on('click', function () {
                var platform = $(this).attr('title');
                dataLayer.push({
                    'event': "productShare",
                    'eventCategory': "Product Engagement",
                    'eventAction': "Product Share",
                    'eventLabel': productInfo.url,
                    'productName': productInfo.name,
                    'productId': productInfo.id,
                    'productSku': productInfo.sku,
                    'productBrand': productInfo.brand,
                    'productPrice': productInfo.price,
                    'productCategory': productInfo.category,
                    'platform': platform
                });
            });
        },

        /**
         * push article track data.
         *
         * @param article
         */
        triggerArticleTrack: function (article) {
            var article = $.parseJSON(article);
            if (article.type !== 'non-article') {
                dataLayer.push({
                    'event': "articleEngagement",
                    'eventCategory': "Article Engagement",
                    'eventAction': "Article View",
                    'eventLabel': article.url,
                    'articleTitle': article.title,
                    'articleSource': article.source,
                    'articlePosition': article.position,
                    'articleType': article.type,
                    'articleCategory': article.category,
                    'articleAuthor': article.author,
                    'articlePublishedDate': article.published,
                    'articleLength': article.length
                });
            }
        },

        /**
         * trigger remarketing tags.
         * @param remarketingInfo
         */
        triggerRemarketingTag: function (remarketingInfo) {
            console.log($.parseJSON(remarketingInfo));
            dataLayer.push($.parseJSON(remarketingInfo) );
        }
    };
});

