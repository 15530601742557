define('Born_Newsletter/js/newsletter-subscription',[
    'jquery',
    'mage/translate',
    'jquery/ui',
    'mage/mage',
    'mage/cookies'
], function ($, $t) {
    'use strict';

    $.widget('born.Newsletter', {

        /** @inheritdoc */
        _create: function () {
            this._bindSubmit();
        },

        /**
         * @private
         */
        _bindSubmit: function () {
            var self = this;

            this.element.on('submit', function (e) {
                e.preventDefault();
                if ($(this).validation('isValid')) {
                    self.submitForm($(this));
                }
            });
        },

        /**
         * Handler for the form 'submit' event
         *
         * @param {Object} form
         */
        submitForm: function (form) {
            var self = this;
            var formData = form.serializeArray();
            formData[0]['value'] = $.mage.cookies.get('form_key');
            $.ajax({
                url: form.attr('action'),
                data: formData,
                type: 'post',
                dataType: 'json',
                showLoader: true,
                /** @inheritdoc */
                beforeSend: function () {
                    self.element.parent().find('.messages').remove();
                },
                success: function (response) {
                    if (response.error) {
                        self.element.after('<div class="messages"><div class="message message-error error"><div>'+response.message+'</div></div></div>');
                          setTimeout(function(){
                          self.element.parent().find('.messages').remove();
                          }, 6000);
                    } else {
                        self.element.find('input').val('');
                        self.element.after('<div class="messages"><div class="message message-success success"><div>'+response.message+'</div></div></div>');
                        setTimeout(function(){
                          self.element.parent().find('.messages').remove();
                        }, 6000);
                    }
                },
                error: function() {
                    self.element.after('<div class="messages"><div class="message message-error error"><div>'+$t('An error occurred, please try again later.')+'</div></div></div>');
                    setTimeout(function(){
                      self.element.parent().find('.messages').remove();
                    }, 6000);
                }
            });
        }
    });

    return $.born.Newsletter;
});

